import * as echarts from 'echarts'

export default {

  props: {
    city: [String, Number],
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      chartInstance: null,
      districtList: []
    }
  },

  watch: {
    data (newVal) {
      this._createEchartsInstance()
    }
  },

  mounted () {
    this._createEchartsInstance()
  },

  methods: {

    init (district) {
      if (typeof district !== 'undefined') this.districtList = district
      this._createEchartsInstance()
    },

    // 创建chart实例
    _createEchartsInstance () {
      setTimeout(() => { // 延迟解决高度问题
        if (this.chartInstance === null && this.$refs.panel) this.chartInstance = echarts.init(this.$refs.panel)
        if (this.districtList.length) this._setOption()
      }, 30)
    },

    randomData () {
      return Math.round(Math.random() * 600)
    }
  }
}
