<template>
  <div ref="panel" style="width: 100%;height: 100%;">

  </div>
</template>
<script>
import distribute from './mixins/distribute'
export default {

  name: 'map-distribute',

  mixins: [distribute],

  data () {
    return {
      max: 1000
    }
  },

  methods: {

    _setOption () {
      const myChart = this.chartInstance
      const splitList = []
      const section = ('0,1,5,10, ' + this.max).split(',').map(Number)
      for (let i = 0; i < section.length - 1; i++) {
        splitList.push({ start: section[i], end: section[i + 1] })
      }
      const color = ['#0356D6', '#46D1F1', '#F2A50A', '#B51E1E']
      let options = {
        backgroundColor: 'transparent',
        visualMap: {
          show: true,
          right: 20,
          bottom: 20,
          textStyle: {
            color: '#FFFFFF'
          },
          // splitList,
          pieces: [
            { gte: 50, color: '#B51E1E' },
            { gte: 10, lt: 50, color: '#F2A50A' },
            { gte: 1, lt: 10, color: '#46D1F1' },
            { value: 0, color: '#0356D6' }
          ],
          inRange: {
            color
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function (data) {
            const html = `
<div class="tooltip1">
      <div >
        <p>${data.name}<img src="/static/images/alarm_red.png"/></p>
        <p>体温异常人数：${data.value}</p>
      </div>
    </div>
`
            return html
          },
          backgroundColor: 'transparent',
          borderRadius: 4,
          borderWidth: 0,
          padding: 0,
          shadowColor: 'rgba(0, 0, 0, 0)',

          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowQuality: 0,
          textStyle: {
            color: '#FFC000'
          }
        },

        series: [
          {
            name: '中国',
            map: this.city,
            zoom: 1, // 当前视角的缩放比例
            roam: true, // 是否开启平游或缩放
            scaleLimit: { // 滚轮缩放的极限控制
              min: 0,
              max: 5
            },
            label: {
              show: true,
              color: '#E3EEFD'
            },
            lineStyle: {
              type: 'solid',
              color: '#000',
              width: 0.5,
              opacity: 1
            },
            type: 'map',
            itemStyle: {
              normal: {
                shadowColor: '#0B368F',
                shadowBlur: 10
              }
            },
            data: this.getData()
            // 自定义名称映射
          }

        ]
      }
      options = { ...options, ...this.setting }
      myChart.setOption(options)
    },

    getData () {
      const data = []
      const list = this.districtList
      console.log(this.data)
      list.forEach(item => {
        const dataItem = {
          name: item.properties.name,
          value: 0
        }
        this.data.forEach(row => {
          if (row.eduName === dataItem.name) {
            dataItem.value = row.totalUnusualNum
          }
        })
        data.push(dataItem)
      })
      // data.forEach(item => item.value = this.randomData())
      return data
    }
  }
}
</script>
